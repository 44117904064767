'use strict';

/*******************************************************************************************/
class UxrGpList_files extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			'listDI': null,
			'itemsDI': null,
		};
		
		this.key = IdGenerator.produce('UxrGpList__');
		
        this.onJQueryMount = this.onJQueryMount.bind(this);
		this.onDragStart = this.onDragStart.bind(this);
		this.onDragEnd = this.onDragEnd.bind(this);
	}
	
	
	/*---------------------------------------------------------------------*/
	styleFromValues(values) {
		var status = _.get(values.data.userInput, 'status.options.value');
		switch (status) {
			case undefined:
				return {color: 'red'};
			case 'processing':
				return {color: 'brown'};
			case 'checking':
				return {color: 'green'};
			case 'release':
				return {color: 'black'};
		}
	}
	
	/*---------------------------------------------------------------------*/
	render() {
		if (this.state.itemsDI === null) {
			return null;
		}
		
		let listItems = [];
		let o = this;
		let itemTree = {};
		_.forEach(this.state.itemsDI, function(eachItemDI) {
			let userNickname = eachItemDI.node.jsonPhp.userNickname;
			let time = eachItemDI.node.jsonPhp.timeString;
			
            let leafProps = {
                key: 'listItem_for_nodeId_'+ eachItemDI.node.id,
                onClick: _.partial(o.props.onEditWish, eachItemDI.node.id),
                onDragStart: _.partial(o.onDragStart, eachItemDI),
                onDragEnd: _.partial(o.onDragEnd, eachItemDI),
                draggable: true,
				style: o.styleFromValues(eachItemDI.values),
            };
            leafProps['data-drag-effect'] = 'all';
            
			let reactLeaf = (
		        <div className="MVT_item" {...leafProps}>
					<div className="MVT_itemTerminal" {...leafProps}>
						<div className="formTable fullWidth">
							<img 
								src={eachItemDI.node.jsonPhp.file.resourceInfo.urlPreview} 
								className="uxrPreviewImage formTableCell vAlignMid" 
							/>
							<div className="uxrPreviewImageName formTableCell vAlignMid alignCenter">{eachItemDI.node.jsonPhp.file.originInfo.name}</div>
						</div>
					</div>
                </div>
			);
			
			//TODO upfold the itemTree
			let path = [
				//userNickname, 
				time,
			];
			let collection = _.get(itemTree, path, []);
			collection.push(reactLeaf);
			_.set(itemTree, path, collection);
		}); //for
		
		let reduxsiveUnfold = function reduxsiveUnfold(nestingDefinition, depthToGo) {
			if (depthToGo === 0) {
				return nestingDefinition;
			}
			let innerNodes = [];
			_.forEach(nestingDefinition, function(value, key) {
				let innerInnerNodes = reduxsiveUnfold(value, depthToGo-1);
				
				innerNodes.push(
					<div className="MVT_list" key={key}>
						<span>{key}</span>
						{innerInnerNodes}
					</div>
				);
			});
			
			return innerNodes;
		};
		
		let itemTreeReduxed = reduxsiveUnfold(itemTree, 1);
		return (
			<div ref={this.onJQueryMount}>
    			<div className="MVT_listMaster">
    				{itemTreeReduxed}
    			</div>
			</div>
		);
	}
	
    /*---------------------------------------------------------------------*/
    onJQueryMount(node) {
        this.jQ = jQuery(node);
    }
    /*---------------------------------------------------------------------*/
	componentDidMount() {
		this.updateState(this.props);
		E.updateController.registerListener(this.key, this.updateListener.bind(this));
	}
	
	/*---------------------------------------------------------------------*/
	componentDidUpdate() {
	}
	
	/*---------------------------------------------------------------------*/
	componentWillReceiveProps(nextProps) {
		this.updateState(nextProps);
	}
	
	/*---------------------------------------------------------------------*/
	updateState(props) {
		let o = this;
		
		E.localCache.dataImage.get(props.listId)
			.then(function(listDI) {
				let assocItems = listDI.values.data.items;
				let itemPromises = [];
				itemPromises.push(Promise.resolve(listDI));
				_.forEach(assocItems.getAll(), function(eachAssocItem) {
					itemPromises.push(eachAssocItem.promise());
				});
				
				Promise
					.all(itemPromises)
					.then(function(items) {
						let listDI = items.shift();
						o.setState({
							'listDI': listDI,
							'itemsDI': items,
						});
					})
					;
			});
	}
	
	/*---------------------------------------------------------------------*/
	updateListener(messages) {
		let relevantNodes = {};
		[this.props.listId].forEach(function(value) {
			relevantNodes[value] = value;
		});
		_.forEach(this.state.itemsDI, function(eachItemDI) {
			let eachId = eachItemDI.node.id;
			relevantNodes[eachId] = eachId;
		});
		
		messages = messages.filter(function(message) {
			return (message.nodeId in relevantNodes);
		});
		
		if (messages.length) {
			this.updateState(this.props);
		}
	}
	/*---------------------------------------------------------------------*/
	onDragStart(dataImage, event) {
		console.log(arguments);
		
		UI.dndData = {
			'uiDonor': this.props.ui,
			'dataImageManipulated': dataImage
		}
		event.dataTransfer.dropEffect = 'link';
	}
	
	/*---------------------------------------------------------------------*/
	onDragEnd(dataImage, event) {
		UI.dndData = null;
	}
	
	/*---------------------------------------------------------------------*/
} //class
